import { mapGetters } from "vuex"
import NavigatonMenuItemsList from "./NavigationMenuItemsList.vue"
import PromoSection from "./PromoSection/PromoSection.vue"

import ContentfulMixin from "../../contentfulComponents/ContentfulMixin"

export default {
  props: {
    active: Boolean
  },

  mixins: [ContentfulMixin],

  contentfulModel: "NavEntry",

  data: () => ({
    currentEntry: null
  }),

  components: {
    NavigatonMenuItemsList,
    PromoSection
  },

  computed: {
    ...mapGetters("content", ["GET_SITE_CONFIG"]),

    getContentfulId() {
      return (
        this.GET_SITE_CONFIG.mainMenu && this.GET_SITE_CONFIG.mainMenu.sys.id
      )
    },

    getRootMenu() {
      return this.getContent
    },

    getRootMenuItems() {
      return (this.getContent && this.getContent.itemsCollection.items) || []
    },

    getNestedNav() {
      let _menuItems = []
      function recurseNavEntries(items) {
        // for each item in getRootMenuItems
        items.forEach(item => {
          if (item.__typename == "NavEntry") {
            _menuItems.push(item)
            if (item.itemsCollection) {
              recurseNavEntries(item.itemsCollection.items)
            }
          }
        })
      }
      recurseNavEntries(this.getRootMenuItems)
      return _menuItems
    },

    getAllNavEntries() {
      // Combine the root menu, the top-level menus, and then any loaded after that
      return []
        .concat(this.getRootMenu, this.getNestedNav)
        .filter(entry => entry && entry.sys.id)
    }
  }
}
