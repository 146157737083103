<template>
  <div
    class="nav-menu-desktop flex--col-start-center"
    :class="{ 'nav-menu-desktop--inverted': isNavInverted }"
    ref="menu"
  >
    <div class="nav-menu-desktop__main-menu">
      <template v-for="(item, index) in getRootMenuItems">
        <button
          v-if="item.__typename === 'NavEntry'"
          :key="index"
          @click="() => triggerMenuActive(item, true, 'click')"
          @mouseenter="() => triggerMenuActive(item, active, 'hover')"
          @mouseleave="() => (active ? initClose() : abortMenuActive())"
          class="nav-menu-desktop__main-menu-item"
          :class="{
            'nav-menu-desktop__main-menu-item--selected':
              currentEntry && currentEntry.sys.id === item.sys.id,
            'nav-menu-desktop__main-menu-item--active': active
          }"
        >
          <span
            class="text-commerce-button-primary text--uppercase"
            :class="isNavInverted ? 'text--clr-black' : 'text--clr-white'"
            >{{ item.title }}</span
          >
        </button>
        <LinkTag
          v-else-if="item.__typename === 'MenuItem'"
          :key="index"
          :link="item.externalLink"
          @mouseenter.native="() => (active ? initClose(true) : null)"
          @click.native="() => handleItemInteraction(item, 'click')"
          class="nav-menu-desktop__main-menu-item flex--col-start-center"
        >
          <span
            class="text-commerce-button-primary text--uppercase"
            :class="isNavInverted ? 'text--clr-black' : 'text--clr-white'"
            >{{ item.title }}</span
          ></LinkTag
        >
      </template>
    </div>
    <div
      class="nav-menu-desktop__dropdown"
      :class="{ 'nav-menu-desktop__dropdown--active': active }"
      @mouseenter="() => (active ? resetClose() : null)"
      @mouseleave="() => (active ? initClose() : null)"
    >
      <div
        class="nav-menu-desktop__dropdown-frame"
        :class="{ 'nav-menu-desktop__dropdown-frame--active': active }"
      >
        <div
          v-for="(entry, index) in getRootMenuItems"
          class="nav-menu-desktop__dropdown-content container--zd flex--row-center-start"
          :key="'root_entry' + index"
          v-show="currentEntry && currentEntry.sys.id === entry.sys.id"
        >
          <div class="nav-menu-desktop__dropdown-section">
            <div class="text-commerce-heading-2 text--clr-black mb-20">
              {{ entry.title }}
            </div>
            <NavigatonMenuItemsList :content="entry" />
          </div>
          <div
            v-for="index in 3"
            :key="index"
            class="nav-menu-desktop__dropdown-section"
          >
            <PromoSection
              :title="entry[`promo${index}Title`]"
              :reference="entry[`promo${index}Reference`]"
              :appearance="entry[`promo${index}Appearance`] || ''"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import GTMAPI from "@/integration/GTMAPI.js"

import NavigationMenuMixin from "./NavigationMenuMixin.js"

import { mapState } from "vuex"

const mouseOverDelay = 200
const mouseOutTimeout = 500

export default {
  name: "NavigationMenuDesktop",

  mixins: [NavigationMenuMixin],

  data: () => ({
    activeTriggerTimeout: null,
    keepActiveTimeout: null
  }),

  computed: {
    ...mapState("ui", ["isNavInverted"])
  },

  methods: {
    initClose(instant = false) {
      const _delay = instant ? 0 : mouseOutTimeout
      this.keepActiveTimeout = setTimeout(() => {
        this.$emit("activated", false)
      }, _delay)
    },

    resetClose() {
      clearTimeout(this.keepActiveTimeout)
      this.keepActiveTimeout = null
    },

    handleItemInteraction(item, interactionType) {
      GTMAPI.trackEvent("navigation.menu.item_selected", {
        item_title: item.title,
        interaction_type: interactionType
      })
      GTMAPI.trackEvent("navigation.menu.loaded", {
        nav_entry_id: this.currentEntry.sys.id,
        nav_entry_title: this.currentEntry.title
      })
    },

    triggerMenuActive(item, instant = false, interactionType) {
      if (this.keepActiveTimeout) {
        this.resetClose()
      }
      const _delay = instant ? 0 : mouseOverDelay
      if (!this.activeTriggerTimeout) {
        this.activeTriggerTimeout = setTimeout(() => {
          this.currentEntry = item
          this.handleItemInteraction(item, interactionType)
          this.$emit("activated", true)
          this.activeTriggerTimeout = null
        }, _delay)
      } else {
        this.currentEntry = item
        this.handleItemInteraction(item, interactionType)
      }
    },

    abortMenuActive() {
      clearTimeout(this.activeTriggerTimeout)
      this.activeTriggerTimeout = null
    }
  },

  watch: {
    active: {
      handler: function(newVal, oldVal) {
        if (newVal !== oldVal) {
          // this.toggleScrollLock(newVal)
          if (!newVal) {
            this.currentEntry = null
          }
        }
      },
      immediate: true
    }
  }
}
</script>

<style lang="scss">
.nav-menu-desktop {
  position: relative;
  background-color: getcolour(bleach_black);
  height: $menu-height-lg;
  width: 100vw;
  z-index: 1;

  &--inverted {
    background-color: getcolour(bleach_white);
  }

  &__main-menu {
    position: relative;
    height: 100%;
    width: 100%;
    max-width: $bleach-site-max-width;
    padding: 0;
    // background-color: lightcoral;
    pointer-events: all;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: stretch;
    z-index: 1;
  }

  &__main-menu-item {
    position: relative;
    background-color: transparent;
    border: none;
    outline: none;
    cursor: pointer;
    padding: 8px 16px 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    &::after {
      content: "";
      position: absolute;
      width: 100%;
      height: 4px;
      bottom: 0;
      left: 0;
      background-color: getcolour(bleach_grey_40);
      opacity: 0;
      transition: opacity 0.05s linear;
    }

    &:hover {
      &::after {
        opacity: 1;
      }
    }

    &--selected {
      &::after {
        background-color: getcolour(bleach_lime);
        opacity: 1;
      }
    }

    &--active {
    }

    // &:last-child {
    //   margin-right: unset;
    // }
  }

  &__dropdown {
    z-index: 0;
    position: absolute;
    top: 100%;
    width: 100%;
    opacity: 0;
    overflow: hidden;
    transition: opacity 0.1s linear 0.1s;

    @include media-breakpoint-up(md) {
      height: calc(100vh - #{$nav-height-md});
    }

    @include media-breakpoint-up(lg) {
      height: calc(100vh - #{$nav-height-lg});
    }

    @include media-breakpoint-up(xl) {
      height: calc(100vh - #{$nav-height-xl});
    }

    &--active {
      opacity: 1;
      transition: opacity 0.1s linear;
    }
  }

  &__dropdown-frame {
    pointer-events: all;
    width: 100%;
    height: auto;
    background-color: getcolour(bleach_white);
    padding-top: 30px;
    padding-bottom: 30px;
    transform: translateY(-100%);
    transition: transform 0.15s ease;

    &--active {
      transform: translateY(0);
    }
  }

  &__dropdown-section {
    $section-count: 4;
    $section-margin: 32px;

    flex: 0 0
      calc(
        (100% / #{$section-count}) -
          (#{$section-margin} * ((#{$section-count} - 1) / #{$section-count}))
      );
    width: calc(
      (100% / #{$section-count}) -
        (#{$section-margin} * ((#{$section-count} - 1) / #{$section-count}))
    );
    margin-right: $section-margin;

    &:last-child {
      margin-right: unset;
    }
  }
}
</style>
