<template>
  <div v-if="getItems.length > 0" class="nav-dropdown-items-list">
    <template v-for="(item, index) in getItems">
      <LinkTag
        interstitial
        v-if="item.__typename == 'MenuItem'"
        :key="'menu_' + index"
        :link="item.externalLink"
        class="nav-dropdown-items-list__item"
        @click="() => handleMenuItemClicked(item, false)"
      >
        <ItemCard
          v-if="showImages"
          :image="item.image && item.image[0]"
          :title="item.title"
        />
        <span v-else class="text-commerce-intro text--clr-black">{{
          item.title
        }}</span>
      </LinkTag>
      <button
        v-else-if="item.__typename == 'NavEntry'"
        :key="'nav_' + index"
        class="nav-dropdown-items-list__item nav-dropdown-items-list__item--button"
        @click="() => handleMenuItemClicked(item, true)"
      >
        <ItemCard
          v-if="showImages"
          :image="item.image && item.image[0]"
          :title="item.title"
          isNav
        />
        <span v-else class="text-commerce-intro text--clr-black">{{
          item.title
        }}</span>
      </button>
    </template>
  </div>
</template>

<script>
import GTMAPI from "@/integration/GTMAPI"

import ItemCard from "./NavigationMenuItemsListItemCard.vue"

import ContentfulMixin from "../../contentfulComponents/ContentfulMixin"

export default {
  mixins: [ContentfulMixin],

  components: {
    ItemCard
  },

  props: {
    // entry: Object,
    showImages: Boolean
  },

  computed: {
    getItems() {
      return (
        (this.getContent &&
          this.getContent.itemsCollection &&
          this.getContent.itemsCollection.items) ||
        []
      )
    }
  },

  methods: {
    handleMenuItemClicked(entry, isNavEntry = false) {
      this.debug.log("clicked", entry.title)
      GTMAPI.trackEvent("navigation.menu.item_selected", {
        menu_title: this.getContent.title,
        item_title: entry.title,
        interaction_type: "click"
      })
      if (isNavEntry) {
        this.$emit("navItemClicked", entry.sys.id)
      } else {
        this.debug.log("emitting linkItemClicked")
        this.$emit("linkItemClicked")
      }
    }
  }
}
</script>

<style lang="scss">
.nav-dropdown-items-list {
  &__item {
    position: relative;
    width: 100%;
    margin-bottom: 16px;

    &--button {
      position: relative;
      border: none;
      outline: none;
      background: none;
      display: block;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}
</style>
