<template>
  <div class="nav-list-item-card flex--row-center-center">
    <div class="nav-list-item-card__image-holder">
      <bleach-image
        v-if="image"
        class="nav-list-item-card__image"
        :src="image.original_secure_url"
        :sizes="{
          sm: [50, 'ar_1']
        }"
        :default-size="[50, 'ar_1']"
        :alt="title"
        imageQuality="60"
      />
    </div>
    <div
      class="nav-list-item-card__text ml-12 text-commerce-button-primary text--left text--clr-black text--uppercase"
    >
      {{ title }}
    </div>
    <div
      v-if="isNav"
      class="nav-list-item-card__arrow-holder flex--col-center-center"
    >
      <Chevron class="nav-list-item-card__arrow" />
    </div>
  </div>
</template>

<script>
import Chevron from "@/assets/chevron-down.svg"

export default {
  components: {
    Chevron
  },

  props: {
    image: Object,
    title: String,
    isNav: Boolean
  }
}
</script>

<style lang="scss">
.nav-list-item-card {
  position: relative;
  width: 100%;
  height: 40px;

  &__image-holder {
    background-color: getcolour(bleach_grey_90);
    flex: 0 0 40px;
    width: 40px;
    height: 100%;
  }

  &__text {
    flex: 1 0 auto;
    width: auto;
  }

  &__arrow-holder {
    width: 32px;
    height: 100%;
    flex: 0 0 32px;
  }

  &__arrow {
    transform: rotate(-90deg);
    width: 16px;
    fill: getcolour(bleach_black);
  }
}
</style>
